<template>
  <div>
    <div v-show="step == 1" class="audit-out-put">
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">申请开票</div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="nextStep">下一步</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="erp-form__body">
        <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
          <div class="mali-edit__form">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item label="发票品类" prop="invoiceVariety">
                  <el-select v-model="formData.invoiceVariety" placeholder="请选择发票品类" class="mali-full__input" @change="changeInvoiceVariety">
                    <el-option v-for="item in Options.invoice_variety" :value="item.value" :label="item.label" :key="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="应收金额" prop="arAmount">
                  <el-input
                    :maxlength="12"
                    v-model="formData.arAmount"
                    clearable
                    :disabled="true"
                    placeholder
                    class="mali-full__inputcom"
                    style="width: 400px"
                  ></el-input>
                  <span
                    class="record__link"
                    style="margin-left: 14px"
                    @click="arVisable = true"
                    v-if="formData.invoiceVariety == 1 || formData.invoiceVariety == 2 || formData.invoiceVariety == 3"
                    >查看</span
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-table
            :data="formData.messageInfoList"
            border
            class="egrid erp-table__list erp-view__table"
            :summary-method="getSummaries"
            :show-summary="formData.messageInfoList.length > 1"
            style="margin-bottom: 30px; width: 100%"
          >
            <el-table-column label="序号" type="index" width="80" align="center" />
            <el-table-column label="开票类型" class-name="no-pd" align="center">
              <template v-slot:header>
                <span class="erp-req">开票类型</span>
              </template>
              <template #default="scope">
                <el-select v-model="scope.row.invoiceType" placeholder="请选择开票类型" class="mali-full__input" @change="changeInvoiceType(scope)">
                  <el-option v-for="item in Options.invoice_output_type" :value="item.value" :label="item.label" :key="item.value"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="本次开票金额" class-name="no-pd" align="center">
              <template v-slot:header>
                <span class="erp-req">本次开票金额</span>
              </template>
              <template #default="scope">
                <el-input
                  :maxlength="11"
                  v-model="scope.row.totalAmount"
                  clearable
                  placeholder="请输入本次开票金额"
                  class="mali-full__inputcom"
                  @blur="changeAmount($event, 'totalAmount', scope)"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="税率" class-name="no-pd" align="center">
              <template v-slot:header>
                <span class="erp-req">税率</span>
              </template>
              <template #default="scope">
                <el-input :maxlength="12" v-model="scope.row.taxRateDesc" :disabled="true" placeholder class="mali-full__inputcom"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="50" class-name="no-pd" align="center">
              <template v-slot:header>
                <span class="el-icon-circle-plus" style="font-size: 18px" @click="addProduct" v-if="formData.messageInfoList.length < 10"></span>
              </template>
              <template #default="scope">
                <span v-if="scope.$index > 0" class="list-del" @click="delItem(scope.row, scope.$index)">
                  <img src="../../../../../assets/images/list-del.png" alt />
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="erp-form__header" style="margin-bottom: 22px">
            <div class="erp-form__title f16" style="margin-left: 16px">购买方</div>
          </div>
          <div class="mali-edit__form" style="padding-top: 0">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item
                  label="名称"
                  prop="buyerName"
                  :rules="
                    isformDataValid
                      ? [
                          {
                            required: true,
                            message: '请填写名称',
                            trigger: 'change',
                          },
                        ]
                      : null
                  "
                >
                  <el-input
                    :maxlength="40"
                    v-model="formData.buyerName"
                    clearable
                    placeholder="请输入名称"
                    class="mali-full__inputcom"
                    @blur="changeValidate($event, 'buyerName')"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="纳税人识别号"
                  prop="buyerTaxPayerNumber"
                  :rules="
                    isformDataValid
                      ? [
                          {
                            required: true,
                            message: '请填写纳税人识别号',
                            trigger: 'change',
                          },
                          {
                            len: 18,
                            message: '长度固定18个字符',
                            trigger: 'blur',
                          },
                        ]
                      : [
                          {
                            len: 18,
                            message: '长度固定18个字符',
                            trigger: 'blur',
                          },
                        ]
                  "
                >
                  <el-input
                    :maxlength="18"
                    v-model="formData.buyerTaxPayerNumber"
                    clearable
                    placeholder="请输入纳税人识别号"
                    class="mali-full__inputcom"
                    @blur="changeValidate($event, 'buyerTaxPayerNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="地址/电话"
                  prop="buyerContactsInfo"
                  :rules="
                    isformDataValid
                      ? [
                          {
                            required: true,
                            message: '请填写地址/电话',
                            trigger: 'blur',
                          },
                        ]
                      : null
                  "
                >
                  <el-input
                    :maxlength="100"
                    v-model="formData.buyerContactsInfo"
                    placeholder="请输入地址/电话"
                    type="textarea"
                    class="inner-88"
                    @blur="changeValidate($event, 'buyerContactsInfo')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="开户行及账号"
                  prop="buyerBankInfo"
                  :rules="
                    isformDataValid
                      ? [
                          {
                            required: true,
                            message: '请填写开户行及账号',
                            trigger: 'change',
                          },
                        ]
                      : null
                  "
                >
                  <el-input
                    :maxlength="100"
                    v-model="formData.buyerBankInfo"
                    placeholder="请输入开户行及账号"
                    type="textarea"
                    class="inner-88"
                    @blur="changeValidate($event, 'buyerBankInfo')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 提交审核步骤2 -->
    <div v-show="step == 2" class="audit-out-put">
      <div class="erp-form__header header-bar__sticky pd16">
        <div class="erp-form__title" v-if="type == 1 || type == 4">申请开票</div>
        <div class="erp-form__title" v-if="type == 2">开票内容</div>
        <div class="erp-form__title" v-if="type == 3">审核开票</div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="submit()" v-if="type == 1 || type == 4">提交审核</el-button>
          <el-button size="small" type="primary" class="erp-primary__btn" @click="audit(2)" v-if="type == 3">审核通过</el-button>
          <el-button size="small" type="primary" class="erp-primary__btn" @click="audit(3)" v-if="type == 3">审核不通过</el-button>
          <div class="erp-default__btn" @click="backStep()">返回</div>
        </div>
      </div>
      <div class="order-base__warp op-16">
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12" v-if="type == 3">
            <erp-view label="销售合同号" :value="baseData.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12" v-if="type == 3">
            <erp-view label="客户" :value="baseData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="发票品类" :value="formData.invoiceVarietyDesc"></erp-view>
          </el-col>
          <!-- <el-col :span="12">
            <erp-view label="开票类型" :value="formData.invoiceTypeDesc"></erp-view>
          </el-col> -->
          <el-col :span="12">
            <erp-view label="应收金额">
              <div style="display: flex; align-items: center">
                <span>{{ formData.arAmount }}</span>
                <span class="record__link2" style="margin-left: 16px" @click="arVisable = true">查看</span>
              </div>
            </erp-view>
          </el-col>
          <!-- <el-col :span="12">
            <erp-view label="本次开票金额" :value="_thousandBitSeparator(formData.totalAmount)"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="税率" :value="formData.taxRateDesc"></erp-view>
          </el-col> -->
        </el-row>
      </div>
      <el-table
        :data="formData.messageInfoList"
        border
        class="egrid erp-table__list erp-view__table"
        :summary-method="getSummaries"
        :show-summary="formData.messageInfoList.length > 1"
        style="margin-top: 20px; margin-bottom: 30px; width: 100%"
      >
        <el-table-column label="序号" type="index" width="130" align="center" />
        <el-table-column label="开票类型" prop="invoiceTypeDesc" class-name="no-pd" align="center"> </el-table-column>
        <el-table-column label="本次开票金额" prop="totalAmountDesc" class-name="no-pd" align="center"> </el-table-column>
        <el-table-column label="税率" prop="taxRateDesc" class-name="no-pd" align="center"> </el-table-column>
      </el-table>
      <!-- 货物信息 -->
      <div class="erp-form__header op-16" style="margin-top: 30px; margin-bottom: 12px">
        <div class="erp-form__title f16">货物信息</div>
        <div v-if="type == 2" class="record__link" @click="exportData">
          <img style="position: relative; top: 3px" src="../../../../../assets/images/erp/icon-export.png" />
          <span>导出</span>
        </div>
      </div>
      <div class="claim_company">
        <el-table
          :data="formData.goodsInfoList"
          border
          class="egrid erp-table__list erp-view__table table-loading"
          :show-summary="summary"
          :summary-method="summaryFn"
          id="tables"
          ref="tables"
        >
          <el-table-column
            label="货物"
            align="center"
            class-name="no-pd"
            show-overflow-tooltip
            width="190"
            v-if="(type == 1 || type == 4) && (formData.invoiceVariety === 2 || formData.invoiceVariety === 3)"
          >
            <template v-slot:header>
              <span class="erp-req">货物</span>
            </template>
            <template #default="scope">
              <el-input v-model="scope.row.item" placeholder :maxlength="15"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="货物" align="center" class-name="no-pd" show-overflow-tooltip width="190" v-else>
            <template #default="scope">
              <!-- 03/05修改 -->
              <el-input v-model="scope.row.item" placeholder :maxlength="20" v-if="type == 1 || type == 4" clearable></el-input>
              <div v-else>{{ filterName(scope.row.item) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="规格型号" align="center" class-name="no-pd" width="145" show-overflow-tooltip>
            <template #default="scope">
              <el-input v-model="scope.row.specification" placeholder :maxlength="15" v-if="type == 1 || type == 4" clearable></el-input>
              <div v-else>{{ filterName(scope.row.specification) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="单位" align="center" width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.unit) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center" show-overflow-tooltip width="160">
            <template #default="scope">
              <div>{{ filterName(scope.row.quantityDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="含税金额" align="center" show-overflow-tooltip width="180">
            <template #default="scope">
              <div>{{ filterName(scope.row.totalAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="税额" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ scope.row.taxAmountDesc }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="formData.invoiceVarietyDesc === '货物发票'" class="op-16">
        <div class="erp-form__title f16" style="margin-top: 31px">报关单</div>
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12">
            <erp-view label="报关单文件" :value="''">
              <div v-if="formData.crmAttachmentVo && formData.crmAttachmentVo.length > 0" class="goods-voice">
                <div class="card-preview" v-for="(item, index) in formData.crmAttachmentVo" :key="index + 'hw'">
                  <span class="file-width">{{ item.name }}</span>
                  <img src="../../../../../assets/images/preview__zoom.png" alt="" @click="$showFiles(item.name, item.url)" />
                </div>
              </div>
              <div v-else>-</div>
            </erp-view>
          </el-col>
        </el-row>
      </div>
      <div class="erp-form__header op-16" style="margin-top: 30px; margin-bottom: 5px">
        <div class="erp-form__title op-16 f16">购买方</div>
      </div>
      <div class="order-base__warp op-16">
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12">
            <erp-view label="名称" :value="formData.buyerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="纳税人识别号" :value="formData.buyerTaxPayerNumber"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="地址/电话" :value="formData.buyerContactsInfo"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="开户行及账号" :value="formData.buyerBankInfo"></erp-view>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 查看应收金额列表 -->
    <el-dialog
      :title="formData.invoiceVariety == 1 ? '货物类应收' : '服务类应收'"
      v-model="arVisable"
      :append-to-body="true"
      width="561px"
      @close="arVisable = false"
      style="padding: 0"
      custom-class="makeInvoice"
    >
      <div class="ar-table">
        <div class="tr">
          <div class="th">费用类型</div>
          <div class="th">应收金额</div>
        </div>
        <div class="tbody">
          <div class="tr" v-for="(item, index) in showArList" :key="index">
            <div class="td">{{ item.feeTypeDisplay }}</div>
            <div class="td">{{ item.amountRmbDesc }}</div>
          </div>
          <div class="tr" v-if="showArList.length > 1">
            <div class="td">合计</div>
            <div class="td">{{ arAmount }}</div>
          </div>
          <div class="tr no-data" v-if="showArList.length === 0">暂无数据</div>
        </div>
      </div>
      <div class="ar-footer">
        <div class="erp-default__btn" @click="arVisable = false">关闭</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { store } from '@/store';
import { exportFile, _thousandBitSeparator } from '@/utils/publicMethods';
import XEUtils from 'xe-utils';
export default {
  components: {},
  props: {
    type: { type: [String, Number] },
    baseData: { type: [Object] }, // 基础信息
    outputAuditId: { type: [String, Number] },
    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      historyTotalAmount: null,
      step: 1, // 步骤条1
      arVisable: false, // 费用列表展示用
      Options: {
        invoice_variety: store.state.dict.options.invoice_variety, //发票品类

        invoice_output_type: store.state.dict.options.invoice_output_type, //开票类型

        invoice_output_default_type: store.state.dict.options.invoice_output_type, //开票类型原始值
      },
      rules: {
        // 检验规则
        invoiceVariety: [
          {
            required: true,
            message: '请选择发票品类',
            trigger: 'change',
          },
        ],
        invoiceType: [
          {
            required: true,
            message: '请选择开票类型',
            trigger: 'change',
          },
        ],
        arAmount: [
          {
            required: true,
            message: '请输入应收金额',
            trigger: 'change',
          },
        ],
        totalAmount: [
          {
            required: true,
            message: '请输入本次开票金额',
            trigger: 'blur',
          },
        ],
        taxRate: [
          {
            required: true,
            message: '请输入税率',
            trigger: 'change',
          },
        ],
      },
      formData: {
        arAmount: null,
        id: null,
        invoiceNumber: null,
        invoiceOutputNo: null,
        // invoiceType: null,
        invoiceVariety: null,
        orderId: null,
        taxAmount: null,
        taxRate: null,
        taxRateDesc: null,
        // totalAmount: null,
        buyerBankInfo: null,
        buyerContactsInfo: null,
        buyerName: null,
        buyerTaxPayerNumber: null,
        goodsInfoList: [],
        messageInfoList: [
          {
            totalAmountDesc: null,
            totalAmount: null,
            invoiceType: null,
            invoiceTypeDesc: null,
            taxRate: null,
            taxRateDesc: null,
          },
        ],
      },
      taxAmountDesc: null,
      // totalAmountDesc: null,
      summary: false,
      arAmountDetails: null, // 应收金额对象
      showArList: [], // 展示不同费用类型的列表
      arAmount: null, // 展示合同金额合计
      buyerInfos: {
        buyerBankInfo: '',
        buyerContactsInfo: '',
        buyerName: '',
        buyerTaxPayerNumber: '',
      }, // 购买方信息
    };
  },
  created() {
    if (this.type === 1) {
      // 1为申请开票 2为查看开票 3为审核开票
      this.formData.orderId = this.baseData.orderId;
      this.queryArAmountDetailByOrderId(this.baseData.orderId); // 获取应收金额详情
      this.queryNewestBuyerInfoByCustomerId(this.baseData.customerId); // 获取购买方信息
    } else {
      // 查看审核开票
      this.getDetailInfo(this.outputAuditId);
      if (this.type !== 4) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    }
  },
  methods: {
    getSummaries(param) {
      let data = param.data;
      if (data.length > 1) {
        let totalAmountCount = 0;
        for (let item of data) {
          if (item.totalAmount) {
            totalAmountCount = XEUtils.add(Number(totalAmountCount), Number(item.totalAmount));
          }
        }
        this.formData.totalAmount = Number(totalAmountCount);
        const sums = ['合计', '', this._thousandBitSeparator(Number(totalAmountCount)), '', ''];
        return sums;
      } else {
        return false;
      }
    },
    addProduct() {
      if (this.formData.messageInfoList.length >= 10) {
        return false;
      } else {
        this.arapAuditSeq += 1;
        const data = {
          totalAmountDesc: null,
          totalAmount: null,
          invoiceType: null,
          invoiceTypeDesc: null,
          taxRate: null,
          taxRateDesc: null,
        };
        this.formData.messageInfoList.push(data);
      }
    },
    delItem(item, index) {
      if (!item.invoiceType && !item.totalAmount) {
        this.delCofirm(item, index);
      } else {
        this.$erpConfirm('确定删除吗？')
          .then(() => {
            this.delCofirm(item, index);
          })
          .catch(() => {
            return false;
          });
      }
    },
    delCofirm(item, index) {
      this.formData.messageInfoList.splice(index, 1);
    },
    exportData() {
      exportFile({
        type: 'POST',
        url: '/malicrm/invoice/output/ExportInvoiceOutputAuditDetail',
        data: {
          id: this.formData.id,
          version: this.formData.version,
        },
      });
    },
    getDetailInfo(outputAuditId) {
      // 获取查看审核详情
      this.ajax({
        type: 'GET',
        url: `/malicrm/invoice/output/queryInvoiceOutputAuditDetailById/${outputAuditId}`,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              // 重新拆箱赋值
              const goodsInfoList = res.result.goodsInfoVo.goodsInfoListVos;
              this.formData = Object.assign(this.formData, res.result);
              this.formData.goodsInfoList = goodsInfoList;
              this.formData.messageInfoList = res.result.messageInfoVo.messageInfo;
              this.taxAmountDesc = res.result.goodsInfoVo.taxAmountDesc;
              this.totalAmountDesc = res.result.goodsInfoVo.totalAmountDesc;
              this.formData.taxAmount = res.result.taxAmount.taxAmount;
              this.historyTotalAmount = res.result.totalAmount;
              if (res.result.erpOrderAttachmentListVos && res.result.erpOrderAttachmentListVos.length > 0) {
                this.formData.crmAttachmentVo = res.result.erpOrderAttachmentListVos;
              }
              this.queryArAmountDetailByOrderId(this.formData.orderId, this.formData.invoiceVariety); // 获取应收金额详情
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    summaryFn() {
      return ['价税合计', '', '', '', this.totalAmountDesc, this.taxAmountDesc];
    },
    changeInvoiceType(scope) {
      // 根据开票类型切换必填选项和回填数据
      const Options = this.Options.invoice_output_type;
      let value = scope.row.invoiceType;
      scope.row.invoiceTypeDesc = Options.filter((v) => v.value === value)[0].label;
      this.ajax({
        type: 'POST',
        url: '/malicrm/invoice/output/queryNowTotalAmountByOrderId',
        data: {
          invoiceType: value,
          invoiceVariety: this.formData.invoiceVariety,
          orderId: this.formData.orderId,
        },
        success: (res) => {
          if (res.code === 200) {
            scope.row.totalAmount = res.result.totalAmount;
            scope.row.totalAmountDesc = res.result.totalAmountDesc;
          } else {
            this.errorTip(res.message);
          }
        },
      });
      if (value === 3) {
        //不开票
        scope.row.taxRate = null;
        scope.row.taxRateDesc = '-';
        let res = true;
        for (let item of this.formData.messageInfoList) {
          if (item.invoiceType === 1 || item.invoiceType === 2 || item.invoiceType === 4) {
            res = false;
            break;
          }
        }
        if (res) this.$refs.form.clearValidate(['buyerName', 'buyerTaxPayerNumber', 'buyerContactsInfo', 'buyerBankInfo']);
      } else if (value === 4) {
        //零税率
        scope.row.taxRate = 0;
        scope.row.taxRateDesc = '0%';
      } else {
        scope.row.taxRate = this.formData.taxRate;
        scope.row.taxRateDesc = this.formData.taxRateDesc;
      }
    },
    changeInvoiceVariety(value) {
      this.formData.invoiceVarietyDesc = this.Options.invoice_variety.filter((v) => v.value === value)[0].label;

      // 根据发票品类修改展示信息
      this.showArListByType(value);
      if (value === 1) {
        // 根据发票品类展示应收金额
        this.formData.taxRate = 9;
        this.formData.taxRateDesc = '9%';
        this.summary = true; // 货物展示合计行用

        this.Options.invoice_output_type = this.Options.invoice_output_default_type.filter((v) => v.value !== 4);
      } else if (value === 2) {
        this.formData.taxRate = 6;
        this.formData.taxRateDesc = '6%';
        this.summary = false;

        this.Options.invoice_output_type = this.Options.invoice_output_default_type;
      } else if (value === 3) {
        this.formData.taxRate = 0;
        this.formData.taxRateDesc = '0%';
        this.summary = false;

        this.Options.invoice_output_type = this.Options.invoice_output_default_type;
      }
      this.formData.messageInfoList = [
        {
          totalAmountDesc: null,
          totalAmount: null,
          invoiceType: null,
          invoiceTypeDesc: null,
          taxRate: null,
          taxRateDesc: null,
        },
      ];
    },
    showArListByType(type) {
      // 根据不同发票类型展示不同数据
      if (type === 1) {
        this.formData.arAmount = this.arAmountDetails.goodsAmountRmbDesc;
        this.showArList = this.arAmountDetails.goodsList; // 默认给货物服务费用
        this.arAmount = this.arAmountDetails.goodsAmountRmbDesc; // 默认给货物服务费用
      } else {
        this.formData.arAmount = this.arAmountDetails.serviceAmountRmbDesc;
        this.showArList = this.arAmountDetails.serviceList; // 服务费用
        this.arAmount = this.arAmountDetails.serviceAmountRmbDesc; // 服务费用
      }
    },
    queryNewestBuyerInfoByCustomerId(customerId) {
      // 获取购买方信息
      this.ajax({
        type: 'GET',
        url: `/malicrm/invoice/output/queryNewestBuyerInfoByCustomerId/${customerId}`,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.buyerInfos = res.result;
              const { buyerBankInfo, buyerContactsInfo, buyerName, buyerTaxPayerNumber } = this.buyerInfos;
              this.formData.buyerBankInfo = buyerBankInfo;
              this.formData.buyerContactsInfo = buyerContactsInfo;
              this.formData.buyerName = buyerName;
              this.formData.buyerTaxPayerNumber = buyerTaxPayerNumber;
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    queryOrderGoodsAmountInfoById() {
      // 获取应收金额
      this.ajax({
        type: 'POST',
        url: '/malicrm/invoice/output/queryOrderGoodsAmountInfoById',
        data: {
          invoiceVariety: this.formData.invoiceVariety,
          orderId: this.baseData.orderId,
          taxRate: this.formData.taxRate,
          totalAmount: this.formData.totalAmount,
        },
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              // 2020-06-10  编辑开票金额后展示的值显示不对，新增编辑都要重新赋值
              this.formData.goodsInfoList = res.result.goodsInfoListVos;
              this.taxAmountDesc = res.result.taxAmountDesc;
              this.totalAmountDesc = res.result.totalAmountDesc;
              this.formData.taxAmount = res.result.taxAmount;
              if (res.result.erpOrderAttachmentListVos && res.result.erpOrderAttachmentListVos.length > 0) {
                this.formData.crmAttachmentVo = res.result.erpOrderAttachmentListVos;
              }
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    queryArAmountDetailByOrderId(orderId, type) {
      this.ajax({
        type: 'GET',
        url: `/malicrm/orderArap/queryArAmountDetailByOrderId/${orderId}`,
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              this.arAmountDetails = res.result;
              this.showArList = this.arAmountDetails.goodsList; // 默认给货物服务费用
              this.arAmount = this.arAmountDetails.goodsAmountRmbDesc; // 默认给货物服务费用
              if (type) {
                this.showArListByType(type);
                if (type === 1) {
                  this.summary = true; // 货物展示合计行用
                }
              }
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    audit(status) {
      // 审核状态
      this.ajax({
        type: 'POST',
        url: '/malicrm/invoice/output/auditOutput',
        data: {
          auditStatus: status,
          id: this.formData.id,
          version: this.formData.version,
        },
        success: (res) => {
          if (res.code === 200) {
            if (status === 2) {
              this.successTip('审核通过');
            } else {
              this.errorTip('审核不通过');
            }
            this.$emit('cancel', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    submit() {
      // 提交审核
      if (this.formData.goodsInfoList.length > 0) {
        if ((this.type === 1 || this.type === 4) && this.formData.invoiceVariety === 2) {
          // 判断服务类型数量必填
          const data = this.formData.goodsInfoList;
          let status = true;
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            if (item.item === '' || item.item === null) {
              status = false;
              this.errorTip('请填写货物名称');
              break;
            }
          }
          if (status === true) {
            this.saveSubmit();
          }
        } else {
          this.saveSubmit();
        }
      } else {
        this.errorTip('请填写所有带星号的必填项');
      }
    },
    saveSubmit() {
      this.ajax({
        type: 'POST',
        url: '/malicrm/invoice/output/saveOutputAudit',
        data: this.formData,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('cancel', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    changeAmount(e, name, scope) {
      let value = e.target.value;
      let index = scope.$index;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, '-'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      value = value.replace('-', '$#$').replace(/-/g, '').replace('$#$', '-');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 100000000 || value <= -100000000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          if (value <= -100000000) {
            value = value.substr(0, 9);
          } else {
            value = value.substr(0, 8);
          }
        }
      }
      if (Number(value) === 0) {
        value = null;
      }

      this.formData.messageInfoList[index][name] = value ? Number(value) : null;
      this.formData.messageInfoList[index][name + 'Desc'] = value ? this._thousandBitSeparator(Number(value)) : null;
    },
    changeValidate(e, name) {
      if (name === 'buyerTaxPayerNumber') {
        let value = e.target.value;
        value = value.replace(/[^0-9a-zA-Z]/g, ''); // 清除“数字”和“.”以外的字符
        this.formData[name] = value;
      }
      this.$refs.form.validateField(name, () => {
        return true;
      });
    },
    validTable() {
      let data = this.formData.messageInfoList;
      let res = true;
      for (let item of data) {
        if (!item.totalAmount || !item.invoiceType) {
          res = false;
          break;
        }
      }
      return res;
    },
    nextStep() {
      this.$refs.form.validate((valid) => {
        if (valid && this.validTable()) {
          let data = this.formData.messageInfoList;
          let totalAmountCount = 0;
          for (let item of data) {
            if (item.totalAmount) {
              totalAmountCount = XEUtils.add(Number(totalAmountCount), Number(item.totalAmount));
            }
          }
          this.formData.totalAmount = totalAmountCount;
          this.ajax({
            type: 'POST',
            url: '/malicrm/invoice/output/orderTotalAmountCheck',
            data: {
              id: this.formData.id,
              orderId: this.formData.orderId,
              totalAmount: this.formData.totalAmount,
              invoiceVariety: this.formData.invoiceVariety,
            },
            success: (res) => {
              if (res.code === 200) {
                // 不管是服务费还是货物费显示都吊最新的接口
                if (this.type === 4 || this.type === 3) {
                  // this.formData.taxAmount = this.formData.goodsInfoList[0].taxAmount;
                }
                if (this.type === 3) {
                  this.queryOrderGoodsAmountInfoById(this.baseData.orderId);
                  this.formData.taxAmount = this.formData.goodsInfoList[0].taxAmount;
                }
                //
                if (this.type === 4 && this.formData.totalAmount === this.historyTotalAmount) {
                  this.step = 2;
                  this.formData.taxAmount = this.formData.goodsInfoVo.taxAmount;
                } else {
                  this.queryOrderGoodsAmountInfoById(this.baseData.orderId); // 根据订单获取产品开票信息
                  this.step = 2;
                }
              } else {
                this.errorTip(res.message);
              }
            },
          });
        } else {
          this.errorTip('请填写所有带星号的必填项');
          return false;
        }
      });
    },
    backStep() {
      if (this.type === 2 || this.type === 3) {
        this.back();
      } else {
        this.step = 1;
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
  computed: {
    isformDataValid() {
      let res = false;
      for (let item of this.formData.messageInfoList) {
        if (item.invoiceType === 1 || item.invoiceType === 2 || item.invoiceType === 4) {
          res = true;
          break;
        }
      }
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
.record__link2 {
  font-size: 14px;
  color: #2878ff;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    font-size: 0;
    margin-right: 5px;
  }
}
.pd16 {
  padding-left: 16px;
  padding-right: 16px;
}
.ar-table {
  .tr.no-data {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color: #333;
    border-left: 1px solid #e5e9f2;
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.audit-out-put {
  .el-table__footer-wrapper {
    margin-top: 0;
  }
  .el-table__footer-wrapper tbody td,
  .el-table__fixed-footer-wrapper tbody td {
    background: #fff;
  }
}
</style>
